<template>
  <div>
    <v-timeline v-if="events.length > 0" align-top dense>
      <v-timeline-item
        v-for="event in events"
        :color="$root.$refs.Calendar.getEventColor(event)"
        v-bind:key="event.id"
        small
      >
        <v-row class="pt-1 pr-15">
          <v-card tile flat>
            <v-card-title class="vct">
              {{ event.name }}
            </v-card-title>
            <v-card-subtitle
              >{{ event.start | date_to_time_formatter }} -
              {{ event.end | date_to_time_formatter }}</v-card-subtitle
            >
            <v-card-text v-if="event.from_user.id != $store.getters.profile.id">
              From {{ event.from_user.first_name }}
            </v-card-text>
            <v-card-text v-else> Мое задание </v-card-text>
            <v-card-text>
              <truncate
                clamp="Показать больше"
                class="read_more"
                :length="80"
                less="Показать меньше"
                type="html"
                :text="event.details"
              ></truncate>
            </v-card-text>
          </v-card>
        </v-row>
      </v-timeline-item>
    </v-timeline>
    <div v-else>
      <v-card tile flat>
        <v-card-title> В этот день нет задач </v-card-title>
      </v-card>
    </div>
  </div>
</template>

<script>
import moment from "moment";
export default {
  name: "DayView",
  props: {
    events: [],
  },
  filters: {
    date_to_time_formatter: function (date) {
      moment.locale("ru");
      let d = date.split(" ")[1];
      return moment(date).format(`dddd ${d}`);
    },
  },
};
</script>

<style lang="scss" scoped>
.read_more button {
  color: #2c6fb2 !important;
}
.vct {
  padding-top: 4px !important;
}
</style>