<template>
  <v-card>
    <v-card-title
      >Чтобы завершить, добавьте комментарий <v-spacer></v-spacer>
      <v-icon @click="$parent.$parent.$parent.completeDialog = false"
        >mdi-close</v-icon
      ></v-card-title
    >
    <v-card-text>
      <v-textarea
        dense
        label="Comment"
        placeholder="Type here..."
        outlined
        v-model="comment_form.comment"
      ></v-textarea>
    </v-card-text>

    <v-card-title>Или добавьте файл</v-card-title>

    <v-card-text>
      <v-file-input
        v-model="comment_form.file"
        truncate-length="15"
        outlined
        show-size
        counter
      >
        <template v-slot:selection="{ text }">
          <v-chip outlined small label color="success">
            {{ text }}
          </v-chip>
        </template>
      </v-file-input>
    </v-card-text>

    <v-card-text v-if="errors != null" class="error--text">
      <v-icon color="error" left>mdi-alert-circle</v-icon> {{ errors }}
    </v-card-text>

    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn text @click="CompleteTask" color="blue darken-1">Save</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { NOTIFICATION_POST } from "@/store/actions/notification";
import { INDIVIDUAL_TASK_PUT } from "@/store/actions/task";

export default {
  data() {
    return {
      comment_form: {
        comment: null,
        file: null,
      },
      errors: null,
    };
  },
  props: {
    task: {},
  },

  methods: {
    async CompleteTask() {
      if (
        this.comment_form.comment == "" ||
        this.comment_form.comment == null &&
        this.comment_form.file == null
      ) {
        this.errors = "One of the fields is required.";
      } else if (this.comment_form.file == null && !this.comment_form.comment) {
        this.errors = "One of the fields is required.";
      }
      if (!this.errors) {
        let resp = await this.$dispatch(INDIVIDUAL_TASK_PUT, {
          id: this.task.id,
          status: "performed_task",
          ...this.comment_form,
        });
        if (resp.success) {
          if (this.task.from_user.id !== this.task.to_users[0].id) {
            let resp2 = await this.$dispatch(NOTIFICATION_POST, {
              from_user: this.$store.getters.profile.id,
              to_users: [this.task.from_user.id],
              individual_task: this.task.id,
              type: "completed_task",
            });
          } else {
          }
          let comment_post = this.$dispatch();
          this.$parent.$parent.$parent.completeDialog = false;
          this.$root.$refs.Tasks.getEvents();
        }
      }
    },
  },
};
</script>

<style>
</style>