<template>
  <div>
    <v-row class="fill-height">
      <v-col>
        <v-sheet height="64">
          <v-tooltip v-if="!is_month_view" bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="success"
                text
                @click="is_month_view = true"
                v-bind="attrs"
                icon
                v-on="on"
                class="mr-3"
                :x-small="$vuetify.breakpoint.xs"
                :small="$vuetify.breakpoint.sm"
              >
                <v-icon>mdi-arrow-left</v-icon>
              </v-btn>
            </template>
            <span>Вернуться к просмотру месяца</span>
          </v-tooltip>
          <v-btn
            color="success"
            class="mr-4"
            dark
            @click.stop="setDialogDate"
            :x-small="$vuetify.breakpoint.xs"
            :small="$vuetify.breakpoint.sm"
          >
            Новое задание
          </v-btn>
          <v-btn
            :x-small="$vuetify.breakpoint.xs"
            :small="$vuetify.breakpoint.sm"
            outlined
            color="success"
            class="mr-4"
            @click="setToday"
          >
            Сегодня
          </v-btn>
          <v-btn :x-small="$vuetify.breakpoint.xs" fab text small @click="prev">
            <v-icon small>mdi-chevron-left</v-icon>
          </v-btn>
          <v-btn :x-small="$vuetify.breakpoint.xs" fab text small @click="next">
            <v-icon small>mdi-chevron-right</v-icon>
          </v-btn>
          <div class="d-inline text-h6 mb-5" v-if="is_month_view">
            {{ month }}
          </div>
          <div class="d-inline text-h6 mb-5" v-else>
            {{ focus | date_formatter }}
          </div>
        </v-sheet>
        <v-dialog max-width="600px" v-model="completeDialog">
          <CommentForm :task="selectedEvent" />
          <!-- <v-card>
            <v-card-title
              >To Complete add comment <v-spacer></v-spacer>
              <v-icon @click="completeDialog = false"
                >mdi-close</v-icon
              ></v-card-title
            >
            <v-card-text>
              <v-textarea
                dense
                label="Comment"
                placeholder="Type here..."
                outlined
                v-model="complete_form.comment"
              ></v-textarea>
            </v-card-text>

            <v-card-title>Or add a file</v-card-title>

            <v-card-text>
              <v-file-input
                multiple
                v-model="complete_form.file"
                truncate-length="15"
                outlined
                show-size
                counter
              >
                <template v-slot:selection="{ text }">
                  <v-chip outlined small label color="success">
                    {{ text }}
                  </v-chip>
                </template>
              </v-file-input>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                text
                @click="CompleteTask(selectedEvent)"
                color="blue darken-1"
                >Save</v-btn
              >
            </v-card-actions>
          </v-card> -->
        </v-dialog>

        <v-dialog max-width="600px" v-model="selectedOpen">
          <v-card>
            <v-card-title :class="getEventColor(selectedEvent) + ' white--text'"
              >{{ selectedEvent.name }} <v-spacer></v-spacer>
              <v-icon @click="selectedOpen = false" color="white"
                >mdi-close</v-icon
              ></v-card-title
            >

            <v-card-title>Подробности</v-card-title>

            <v-card-text>
              <v-text-field
                dense
                readonly
                :value="selectedEvent.details"
              ></v-text-field>
            </v-card-text>

            <v-card-title>Начало</v-card-title>

            <v-card-text>
              <v-text-field
                dense
                readonly
                :value="selectedEvent.start"
              ></v-text-field>
            </v-card-text>
            <v-card-title>Завершение</v-card-title>

            <v-card-text>
              <v-text-field
                dense
                readonly
                :value="selectedEvent.end"
              ></v-text-field>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                v-if="!selectedEvent.is_accepted"
                class="white--text"
                @click="AcceptTask(selectedEvent)"
                :color="getEventColor(selectedEvent)"
                >Принять задачу</v-btn
              >
              <v-btn
                v-show="selectedEvent.status == 'current_task'"
                class="white--text"
                @click="completeDialog = true"
                :color="getEventColor(selectedEvent)"
                >Завершить задачу</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-dialog v-model="dialogDate" max-width="650">
          <v-card>
            <v-card-title>Создать задачу</v-card-title>
            <v-container>
              <v-form @submit.prevent="addEvent">
                <v-card-text>
                  <v-text-field
                    :error-messages="nameErrors"
                    v-model="task_form.name"
                    color="success"
                    type="text"
                    label="Название задачи"
                  ></v-text-field>
                  <v-textarea
                    v-model="task_form.details"
                    :error-messages="detailsErrors"
                    label="Подробности задачи"
                    color="success"
                  ></v-textarea>
                  <v-row>
                    <v-col>
                      <v-menu
                        v-model="menu2"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="task_form.start"
                            :error-messages="startErrors"
                            label="Дата начала"
                            append-icon="mdi-calendar"
                            readonly
                            v-bind="attrs"
                            color="success"
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          :first-day-of-week="1"
                          v-model="task_form.start"
                          @input="menu2 = false"
                          locale="ru-RU"
                          color="success"
                          :max="task_form.end"
                        ></v-date-picker>
                      </v-menu>
                    </v-col>
                    <v-col v-if="!is_fullday">
                      <v-select
                        prepend-icon="mdi-clock-time-four-outline"
                        v-model="time_start"
                        :items="times"
                        label="Начало"
                        :error-messages="timeStartErrors"
                      ></v-select>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col v-show="!is_repeated">
                      <v-menu
                        v-model="menu3"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="task_form.end"
                            label="Дата окончания"
                            :error-messages="endErrors"
                            append-icon="mdi-calendar"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                            color="success"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          :first-day-of-week="1"
                          v-model="task_form.end"
                          color="success"
                          locale="ru-RU"
                          @input="menu3 = false"
                          :min="task_form.start"
                        ></v-date-picker>
                      </v-menu>
                    </v-col>
                    <v-col v-if="!is_fullday">
                      <v-select
                        prepend-icon="mdi-clock-time-four-outline"
                        v-model="time_end"
                        :items="times"
                        label="Окончание"
                        :error-messages="timeEndErrors"
                      ></v-select>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="3">
                      <v-checkbox
                        v-model="is_fullday"
                        color="success"
                        label="Полный день"
                      ></v-checkbox>
                    </v-col>
                    <v-col cols="3">
                      <v-checkbox
                        v-model="is_repeated"
                        color="success"
                        label="Повторение"
                        @change="RepeatChange"
                      ></v-checkbox>
                    </v-col>
                    <v-dialog
                      @click:outside="NoRepeat"
                      v-model="repeat_dialog"
                      max-width="400"
                    >
                      <v-card>
                        <v-card-title
                          >Повторить задачу <v-spacer></v-spacer>
                          <v-icon @click="NoRepeat"
                            >mdi-close</v-icon
                          ></v-card-title
                        >
                        <v-form>
                          <v-card-text>
                            <v-select
                              label="Повторение"
                              v-model="repeat_form.type"
                              item-text="name"
                              :items="[
                                { name: 'Ежедневно', text: 'дней' },
                                { name: 'Еженедельно', text: 'недели' },
                                { name: 'Ежемесячно', text: 'месяцы' },
                                { name: 'Ежегодно', text: 'годы' },
                              ]"
                              return-object
                            ></v-select>
                          </v-card-text>
                          <v-card-text>
                            <v-text-field
                              type="number"
                              label="Каждый"
                              v-model="repeat_form.every"
                              :suffix="repeat_form.type.text + ' один раз'"
                            ></v-text-field>
                          </v-card-text>
                          <v-card-text>
                            <v-menu
                              v-model="repeat_end_date"
                              :close-on-content-click="false"
                              transition="scale-transition"
                              offset-y
                              min-width="auto"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                  prefix="Заканчивается"
                                  v-model="task_form.end"
                                  label="Дата окончания"
                                  :error-messages="endErrors"
                                  append-icon="mdi-calendar"
                                  readonly
                                  v-bind="attrs"
                                  v-on="on"
                                  color="success"
                                ></v-text-field>
                              </template>
                              <v-date-picker
                                :first-day-of-week="1"
                                v-model="task_form.end"
                                color="success"
                                locale="ru-RU"
                                @input="repeat_end_date = false"
                                :min="task_form.start"
                              ></v-date-picker>
                            </v-menu>
                          </v-card-text>
                          <v-card-subtitle class="text-no-wrap secondary">
                            Каждый {{ repeat_form.every }}
                            {{ repeat_form.type.text }} до
                            {{ task_form.end }}
                          </v-card-subtitle>
                          <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn text color="success" @click="RepeatSave"
                              >сохранить</v-btn
                            >
                          </v-card-actions>
                        </v-form>
                      </v-card>
                    </v-dialog>
                    <v-col cols="6">
                      <v-select
                        v-show="
                          $store.getters.profile.user_access_level == 'ceo' ||
                            $store.getters.profile.user_access_level ==
                              'admin' ||
                            $store.getters.profile.user_access_level ==
                              'depdir' ||
                            $store.getters.profile.user_access_level == 'hod'
                        "
                        :items="types"
                        label="Выберите тип"
                        v-model="type"
                      >
                      </v-select>
                    </v-col>
                  </v-row>
                  <v-autocomplete
                    v-model="task_form.to_users"
                    :disabled="x_loading"
                    :items="workers"
                    chips
                    v-show="
                      type == 'Лицам' &&
                        ($store.getters.profile.user_access_level == 'ceo' ||
                          $store.getters.profile.user_access_level == 'admin' ||
                          $store.getters.profile.user_access_level ==
                            'depdir' ||
                          $store.getters.profile.user_access_level == 'hod')
                    "
                    dense
                    color="success"
                    label="Выбрать сотрудников"
                    item-text="name"
                    item-value="id"
                    multiple
                  >
                    <template v-slot:selection="data">
                      <v-chip
                        v-bind="data.attrs"
                        :input-value="data.selected"
                        close
                        dense
                        color="success"
                        class="mb-2"
                        @click="data.select"
                        @click:close="remove(data.item)"
                      >
                        <v-avatar left>
                          <v-img :src="data.item.avatar"></v-img>
                        </v-avatar>
                        {{ data.item.name }}
                      </v-chip>
                    </template>
                    <template v-slot:prepend-item>
                      <v-divider class="mt-2"></v-divider>
                    </template>
                    <template v-slot:item="data">
                      <template>
                        <v-list-item-avatar>
                          <img :src="data.item.avatar" />
                        </v-list-item-avatar>
                        <v-list-item-content>
                          <v-list-item-title
                            v-html="data.item.name"
                          ></v-list-item-title>
                          <v-list-item-subtitle
                            v-html="data.item.group"
                          ></v-list-item-subtitle>
                        </v-list-item-content>
                      </template>
                    </template>
                  </v-autocomplete>
                  <v-select
                    label="Выберите отдел"
                    :items="deparments"
                    v-show="
                      type == 'Отделы' &&
                        ($store.getters.profile.user_access_level == 'ceo' ||
                          $store.getters.profile.user_access_level == 'admin' ||
                          $store.getters.profile.user_access_level == 'depdir')
                    "
                  ></v-select>
                </v-card-text>
              </v-form>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  type="submit"
                  color="success"
                  class="mr-4"
                  @click.stop="addEvent"
                >
                  сохранить
                </v-btn>
              </v-card-actions>
            </v-container>
          </v-card>
        </v-dialog>

        <v-card :loading="loading" tile flat height="600">
          <v-calendar
            locale="ru-RU"
            :weekdays="[1, 2, 3, 4, 5, 6, 0]"
            ref="calendar"
            v-model="focus"
            first-interval="9"
            interval-count="12"
            color="success"
            v-show="is_month_view"
            :events="events"
            :event-color="getEventColor"
            :event-margin-bottom="3"
            :now="today"
            :show-month-on-first="false"
            :type="calendar_type"
            @click:event="showEvent"
            @click:more="viewDay"
            @click:day="viewDay"
            @click:date="setDialogDate"
            @change="updateRange"
          ></v-calendar>
          <DayView :events="daily_events" v-show="!is_month_view"> </DayView>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import {
  TASK_DELETE,
  TASK_EDIT,
  TASK_PATCH,
  INDIVIDUAL_TASKS_POST,
  INDIVIDUAL_TASKS_UNTIL_POST,
} from "@/store/actions/task";
import { DEPARTMENTS_GET } from "@/store/actions/department";
import { required } from "vuelidate/lib/validators";
import moment from "moment";
import { NOTIFICATION_POST } from "@/store/actions/notification";

export default {
  name: "Calendar",
  props: {
    events: {
      type: Array,
      default: () => [],
    },
    loading: true,
  },
  data() {
    return {
      daily_events: [],
      is_month_view: true,
      people: [],
      autoUpdate: true,
      isUpdating: false,
      repeat_end_date: false,
      repeat_form: {
        type: { name: "Ежедневно", text: "дней" },
        every: 1,
      },
      menu2: false,
      menu3: false,
      menu4: false,
      menu5: false,
      today: new Date().toISOString().substr(0, 10),
      focus: new Date().toISOString().substr(0, 10),
      calendar_type: "month",
      month: "",
      deparments: [],
      times: [
        "09:00",
        "09:30",
        "10:00",
        "10:30",
        "11:00",
        "11:30",
        "12:00",
        "12:30",
        "13:00",
        "13:30",
        "14:00",
        "14:30",
        "15:00",
        "15:30",
        "16:00",
        "16:30",
        "17:00",
        "17:30",
        "18:00",
        "18:30",
        "19:00",
        "19:30",
        "20:00",
        "20:30",
        "21:00",
      ],
      time_start: null,
      time_end: null,
      completeDialog: false,
      is_fullday: true,
      is_repeated: false,
      type: "Личный",
      repeat_dialog: false,
      task_form: {
        from_user: null,
        name: null,
        details: null,
        start: null,
        end: null,
        to_users: [],
        status: "new_task",
      },
      currentlyEditing: null,
      selectedEvent: {},
      selectedElement: null,
      selectedOpen: false,
      dialog: false,
      dialogDate: false,
      complete_form: {
        comment: null,
        file: null,
      },
      getWorkers: null,
      api: process.env.VUE_APP_API_URL.slice(0, -1),
    };
  },
  filters: {
    date_formatter: function(date) {
      moment.locale("ru");
      return moment(date)
        .format("MMMM D")
        .toUpperCase();
    },
  },
  created() {
    moment.locale("ru");
    this.month = moment()
      .format("MMMM D")
      .toUpperCase();
    this.getWorker();
    this.$root.$refs.Calendar = this;
  },
  computed: {
    types() {
      switch (this.$store.getters.profile.user_access_level) {
        case "ceo":
          return ["Отделы", "Лицам", "Личный"];
        case "admin":
          return ["Отделы", "Лицам", "Личный"];
        case "depdir":
          return ["Отделы", "Лицам", "Личный"];
        default:
          return ["Лицам", "Личный"];
      }
    },
    workers() {
      switch (this.$store.getters.profile.user_access_level) {
        case "ceo":
          let w = [];
          return this.people;
        case "admin":
          return this.people;
        case "depdir":
          return this.people;
        case "hod":
          let workers = [];
          let resp = this.getWorkers;
          if (resp) {
            for (let d in resp.result) {
              if (resp.result[d].hod.id == this.$store.getters.profile.id) {
                workers.push({ header: resp.result[d].name });
                for (let w in resp.result[d].workers) {
                  workers.push({
                    id: resp.result[d].workers[w].id,
                    name: `${resp.result[d].workers[w].first_name} ${resp.result[d].workers[w].last_name}`,
                    group: resp.result[d].name,
                    avatar: `${this.api}${resp.result[d].workers[w].image}`,
                  });
                }
              }
            }
          }

          return workers;
        default:
          return [];
      }
    },
    nameErrors() {
      const errors = [];
      if (!this.$v.task_form.name.$dirty) return errors;
      !this.$v.task_form.name.required && errors.push("Name is required");
      return errors;
    },
    detailsErrors() {
      const errors = [];
      if (!this.$v.task_form.details.$dirty) return errors;
      !this.$v.task_form.details.required &&
        errors.push("Details are required");
      return errors;
    },
    startErrors() {
      const errors = [];
      if (!this.$v.task_form.start.$dirty) return errors;
      !this.$v.task_form.start.required &&
        errors.push("Start date is required");
      return errors;
    },
    endErrors() {
      const errors = [];
      if (!this.$v.task_form.end.$dirty) return errors;
      !this.$v.task_form.end.required && errors.push("End date is required");
      return errors;
    },
    timeStartErrors() {
      const errors = [];
      if (!this.$v.time_start.$dirty) return errors;
      !this.$v.time_start.required && errors.push("Start time is required");
      return errors;
    },
    timeEndErrors() {
      const errors = [];
      if (!this.$v.time_end.$dirty) return errors;
      !this.$v.time_end.required && errors.push("End time is required");
      return errors;
    },
    title() {
      const { start, end } = this;
      if (!start || !end) {
        return "";
      }
      const startMonth = this.monthFormatter(start);
      const endMonth = this.monthFormatter(end);
      const suffixMonth = startMonth === endMonth ? "" : endMonth;
      const startYear = start.year;
      const endYear = end.year;
      const suffixYear = startYear === endYear ? "" : endYear;
      const startDay = start.day + this.nth(start.day);
      const endDay = end.day + this.nth(end.day);
      switch (this.calendar_type) {
        case "month":
          return `${startMonth} ${startYear}`;
        case "week":
        case "4day":
          return `${startMonth} ${startDay} ${startYear} - ${suffixMonth} ${endDay} ${suffixYear}`;
        case "day":
          return `${startMonth} ${startDay} ${startYear}`;
      }
      return "";
    },
    monthFormatter() {
      return this.$refs.calendar.getFormatter({
        timeZone: "GMT+5",
        month: "long",
      });
    },
  },
  methods: {
    remove(item) {
      const index = this.task_form.to_users.indexOf(item.id);
      if (index >= 0) this.task_form.to_users.splice(index, 1);
    },
    async getWorker() {
      let resp = await this.$dispatch(DEPARTMENTS_GET);
      let workers = [];
      for (let d in resp.result) {
        this.deparments.push(resp.result[d].name);
        workers.push({ header: resp.result[d].name });
        workers.push({
          id: resp.result[d].hod.id,
          name: `${resp.result[d].hod.first_name} ${resp.result[d].hod.last_name}`,
          group: `${resp.result[d].name} - Hачальник отдела`,
          avatar: `${this.api}${resp.result[d].hod.image}`,
        });
        for (let w in resp.result[d].workers) {
          workers.push({
            id: resp.result[d].workers[w].id,
            name: `${resp.result[d].workers[w].first_name} ${resp.result[d].workers[w].last_name}`,
            group: resp.result[d].name,
            avatar: `${this.api}${resp.result[d].workers[w].image}`,
          });
        }
      }
      this.people = workers;
      this.getWorkers = resp;
    },
    setDialogDate(e) {
      this.task_form.start = "";
      if (e.date) {
        var date = new Date(e.date).toISOString().substr(0, 10);
        this.task_form.start = date;
      }
      this.dialogDate = true;
    },
    viewDay({ date }) {
      let events = [];
      for (let event in this.events) {
        let start = moment(this.events[event].start).format("YYYY-MM-DD");
        if (start == date) {
          events.push(this.events[event]);
        }
      }
      this.daily_events = events;
      this.is_month_view = false;
      this.focus = date;
    },
    getEventColor(event) {
      let deadline = moment(event.end).format("YYYY-MM-DD");
      if (
        deadline == moment().format("YYYY-MM-DD") &&
        event.status != "performed_task"
      ) {
        return "error";
      } else if (
        deadline ==
          moment()
            .add(1, "day")
            .format("YYYY-MM-DD") &&
        event.status != "performed_task"
      ) {
        return "warning";
      } else if (event.status === "current_task") {
        if (deadline <= moment().format("YYYY-MM-DD")) {
          return "error";
        }
      }
      let colors = {
        current_task: "success",
        new_task: "green",
        performed_task: "grey",
      };
      return colors[event.status];
    },
    setToday() {
      if (this.is_month_view) {
        this.focus = this.today;
        this.month = moment(this.today)
          .format("MMMM YYYY")
          .toUpperCase();
      } else {
        let d = moment().format("YYYY-MM-DD");
        this.viewDay({ date: d });
        this.focus = d;
      }
    },
    async prev() {
      if (this.is_month_view) {
        await this.$refs.calendar.prev();
        this.month = this.$refs.calendar.title.toUpperCase();
      } else {
        let d = moment(this.focus)
          .subtract(1, "day")
          .format("YYYY-MM-DD");
        this.viewDay({ date: d });
        this.focus = d;
      }
    },
    async next() {
      if (this.is_month_view) {
        await this.$refs.calendar.next();
        this.month = this.$refs.calendar.title.toUpperCase();
      } else {
        let d = moment(this.focus)
          .add(1, "day")
          .format("YYYY-MM-DD");
        this.viewDay({ date: d });
        this.focus = d;
      }
    },
    async addEvent() {
      this.$v.task_form.$touch();
      this.$v.$touch();
      if (!this.$v.task_form.$invalid) {
        let resp;
        this.task_form.from_user = this.$store.getters.profile.id;
        if (!this.is_fullday) {
          this.task_form.start += ` ${this.time_start}`;
          this.task_form.end += ` ${this.time_end}`;
        } else {
          this.task_form.start += ` 09:00`;
          this.task_form.end += ` 18:00`;
        }
        if (this.type === "Department") {
        } else {
          if (this.type === "Personal") {
            this.task_form.is_accepted = true;
            this.task_form.status = "current_task";
            this.task_form.to_users = [this.$store.getters.profile.id];
            if (this.is_repeated) {
              this.task_form.every = this.repeat_form.every;
              this.task_form.until = moment(this.task_form.end).format(
                "YYYY-MM-DD"
              );
              if (!this.is_fullday) {
                this.task_form.end = moment(this.task_form.start).format(
                  `YYYY-MM-DD ${this.time_end}`
                );
              } else {
                this.task_form.end = moment(this.task_form.start).format(
                  `YYYY-MM-DD 18:00`
                );
              }

              resp = await this.$dispatch(
                INDIVIDUAL_TASKS_UNTIL_POST,
                this.task_form
              );
            } else {
              resp = await this.$dispatch(
                INDIVIDUAL_TASKS_POST,
                this.task_form
              );
            }
            this.dialogDate = false;
          } else {
            if (this.is_repeated) {
              this.task_form.every = this.repeat_form.every;
              this.task_form.until = moment(this.task_form.end).format(
                "YYYY-MM-DD"
              );
              if (!this.is_fullday) {
                this.task_form.end = moment(this.task_form.start).format(
                  `YYYY-MM-DD ${this.time_end}`
                );
              } else {
                this.task_form.end = moment(this.task_form.start).format(
                  `YYYY-MM-DD 18:00`
                );
              }
              resp = await this.$dispatch(
                INDIVIDUAL_TASKS_UNTIL_POST,
                this.task_form
              );
            } else {
              resp = await this.$dispatch(
                INDIVIDUAL_TASKS_POST,
                this.task_form
              );
            }
            this.dialogDate = false;
          }
        }
        if (resp.success) {
          this.$root.$refs.Tasks.getEvents();
          if (!this.is_month_view) {
            this.viewDay({ date: this.focus });
          }
          let resp2;
          if (this.is_repeated && this.type !== "Personal") {
            resp2 = await this.$dispatch(NOTIFICATION_POST, {
              from_user: resp.result[0].from_user,
              to_users: resp.result[0].to_users,
              individual_task: resp.result[0].id,
              type: "new_task",
            });
          } else if (this.type !== "Personal") {
            resp2 = await this.$dispatch(NOTIFICATION_POST, {
              from_user: resp.result.from_user,
              to_users: resp.result.to_users,
              individual_task: resp.result.id,
              type: "new_task",
            });
          }
          this.task_form.name = "";
          this.task_form.details = "";
          this.task_form.start = "";
          this.task_form.end = "";
        } else {
          alert(resp);
        }
      }
    },
    editEvent(ev) {
      this.currentlyEditing = ev.id;
    },
    async updateEvent(ev) {
      let resp = await this.$dispatch(TASK_EDIT, ev);
      if (resp.success) {
        (this.selectedOpen = false), (this.currentlyEditing = null);
      } else {
        alert(resp);
      }
    },
    async deleteEvent(ev) {
      let resp = await this.$dispatch(TASK_DELETE, ev.id);
      if (resp.success) {
        this.selectedOpen = false;
      } else {
        alert(resp);
      }
    },
    showEvent({ nativeEvent, event }) {
      const open = () => {
        this.selectedEvent = event;
        this.selectedElement = nativeEvent.target;
        setTimeout(() => (this.selectedOpen = true), 10);
      };
      if (this.selectedOpen) {
        this.selectedOpen = false;
        setTimeout(open, 10);
      } else {
        open();
      }
      nativeEvent.stopPropagation();
    },
    async AcceptTask(event) {
      let resp = await this.$dispatch(TASK_PATCH, {
        id: event.id,
        is_accepted: true,
        status: "current_task",
      });
      if (resp.success) {
        let resp2 = await this.$dispatch(NOTIFICATION_POST, {
          from_user: this.$store.getters.profile.id,
          to_users: [event.from_user.id],
          task: event.id,
          type: "accepted_task",
        });
        this.selectedOpen = false;
        this.$root.$refs.Tasks.getEvents();
      } else {
        event.is_accepted = false;
      }
    },
    async CompleteTask(event) {
      let resp = await this.$dispatch(TASK_PATCH, {
        id: event.id,
        status: "performed_task",
      });
      if (resp.success) {
        if (event.from_user.id !== event.to_users[0].id) {
          let resp2 = await this.$dispatch(NOTIFICATION_POST, {
            from_user: this.$store.getters.profile.id,
            to_users: [event.from_user.id],
            task: event.id,
            type: "completed_task",
          });
        } else {
        }
        this.selectedOpen = false;
        this.$root.$refs.Tasks.getEvents();
      }
    },
    RepeatChange() {
      this.repeat_dialog = true;
    },
    RepeatSave() {
      if (this.is_repeated) {
        this.repeat_dialog = false;
        this.is_repeated = true;
      } else {
        this.is_repeated = false;
        this.repeat_dialog = false;
      }
    },
    NoRepeat() {
      this.repeat_dialog = false;
      this.is_repeated = false;
    },
    updateRange({ start, end }) {
      this.start = start;
      this.end = end;
    },
    nth(d) {
      return d > 3 && d < 21
        ? "th"
        : ["th", "st", "nd", "rd", "th", "th", "th", "th", "th", "th"][d % 10];
    },
    OnFileChange(e) {
      var files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      this.complete_form.file = files[0];
    },
  },
  validations() {
    return {
      task_form: {
        name: {
          required,
        },
        details: {
          required,
        },
        start: {
          required,
        },
        end: {
          required,
        },
      },
      time_start: {
        required,
      },
      time_end: {
        required,
      },
    };
  },
  components: {
    DayView: () =>
      import(
        /* webpackChunkName: "calendar" */
        "@/components/CalendarDayView"
      ),
    CommentForm: () =>
      import(
        /* webpackChunkName: "calendar" */
        "@/components/CommentForm"
      ),
  },
};
</script>

<style scoped lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;500&display=swap");
$body-font-family: "Roboto";

.fileUpload {
  background: #2c6fb2;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  color: #fff;
  font-size: 1em;
  font-weight: bold;
  margin: 1.25em auto; /*20px/16px 0*/
  overflow: hidden;
  padding: 0.5em; /*14px/16px*/
  position: relative;
  text-align: center;
  width: 100%;
  cursor: pointer;
  box-shadow: 0px 0px 7px 1px rgba(0, 0, 0, 0.46);
  -webkit-box-shadow: 0px 0px 7px 1px rgba(0, 0, 0, 0.46);
  -moz-box-shadow: 0px 0px 7px 1px rgba(0, 0, 0, 0.46);
}
.fileUpload:hover,
.fileUpload:active,
.fileUpload:focus {
  background: #3575b6;
  cursor: pointer;
}
.fileUpload input.upload {
  position: absolute;
  top: 0;
  right: 0;
  margin: 0;
  padding: 0;
  font-size: 20px;
  cursor: pointer;
  opacity: 0;
  filter: alpha(opacity=0);
  width: 100%;
  height: 46px;
  cursor: pointer;
}
.fileUpload > span {
  font-weight: 500;
  letter-spacing: 0.0892857143em;
  font-size: 0.875rem;
  font-family: $body-font-family;
}

input[type="file"] {
  position: fixed;
  right: 100%;
  bottom: 100%;
}
</style>
